import React, { useState } from 'react'
import { useNavigate, useSearchParams, useOutletContext } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


import CustomizableTable from '../shared/CustomizableTable';
import FormDialog from '../shared/FormDialog';
import MethaneForm from './MethaneForm';

function AnnouncementList({ title }) {

  const [announcements, drills] = useOutletContext();

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const navigate = useNavigate();

  const headCells = [
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'Title',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: true,
      label: 'Description',
    },
    {
      id: 'major',
      numeric: true,
      boolean:true,
      disablePadding: true,
      label: 'Major',
    },
    {
      id: 'incident_type.incident_type_name',
      numeric: false,
      disablePadding: false,
      label: 'Incident Type',
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: 'Location',
    },

  ];

  const [open, setOpen] = useState(false);
  return (
    <div>
      <Box sx={{ display: "flex", width: "100vw", rowGap: "20px", margin: "10px", alignItems: 'center' }}>
        <Tooltip title="New Annoucement">
          <IconButton onClick={() => title == "Announcements" ? navigate("/activation/announce") : navigate("/activation/simulate")}>
            <AddBoxOutlinedIcon color="primary" fontSize="large" />
          </IconButton>
        </Tooltip>
        <TextField
          id="standard-search"
          label="Search field"
          type="search"
          variant="standard"
          color="primary"
          size="large"
        />
      </Box>


      <CustomizableTable title={title} rows={title==="Announcements" ? announcements : drills} headCells={headCells} />
    </div>
  )
}

export default AnnouncementList