import React, { useState } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DangerousIcon from '@mui/icons-material/Dangerous';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';

const drawerWidth = 240;

const sideBarElements = [

  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "dashboard_icon",
    children:[]
  },
  {
    title: "Announcements",
    path: "/activation",
    icon: "notification_add_icon",
    children:[
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: "dashboard_icon"
      },
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: "dashboard_icon"
      },
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: "dashboard_icon"
      },
    ]
  },
  {
    title: "Incidents",
    path: "/team",
    icon: "dangerous_icon",
    children:[]
  },
  {
    title: "Team",
    path: "/team",
    icon: "groups_icon",
    children:[]
  },
  {
    title: "Stakeholders +",
    path: "/team",
    icon: "account_balance_icon",
    children:[]
  },
  {
    title: "Inventory",
    path: "/team",
    icon: "inventory_icon",
    children:[]
  },
  {
    title: "Reports +",
    path: "/team",
    icon: "summarize_icon",
    children:[]
  },


]


function Sidebar(props) {
  const { window } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };


  const handleDrawerToggle = () => {
    setIsOpen(!isOpen)
  };

  const drawer = (
    <div>
      <Box>
        <img src="../dhafra-logo.png" height="auto" width="300px" />
      </Box>
      <div>
        
      </div>

      <List>
        {sideBarElements.map(({ title, icon, path, children }) => (
          <ListItem key={title} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Icon color="secondary">{icon}</Icon>
              </ListItemIcon>
              <Link style={{ textDecoration: "none" }} to={path}><ListItemText primary={title} /></Link>

            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100%)` },
          ml: { sm: `${drawerWidth}px` },

        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          

          <Typography variant="h6" noWrap component="div">
            {props.title}
          </Typography>
          {props?.header}
        </Toolbar>
      </AppBar>
      {isOpen && <Box
        component="nav"
        sx={{ width: { xs: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={handleDrawerClose}
        >
          {drawer}
        </Drawer>
      </Box>}
      <div style={{ width: "100vw" }}>
        <Toolbar />
        {props.children}
      </div>
    </Box>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default Sidebar;
