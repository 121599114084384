import React, { useRef, useState } from 'react'
import GoogleMapReact from "google-map-react";
import DashboardIncidentMarker from './DashboardIncidentMarker';







const ambulanceData = [
  { value: 5, label: 'At Scene' },
  { value: 10, label: 'On the Way' },
  { value: 10, label: 'On Stand By' },
];

function DashboardMap({ lat = 24.491241, lng = 54.37831961, details = "", announcements = [] }) {

  const mapRef = useRef(null);
  const mapsRef = useRef(null);

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 24.491241,
      lng: 54.37831961,
    },
    zoom: 13,
    minZoom: 10 // Set a minimum zoom level
  });

  const handleApiLoaded = (map, maps) => {
    // Create a new LatLngBounds object
    const bounds = new maps.LatLngBounds();

    // Extend the bounds with each marker's position
    announcements.forEach((announcement) => {
      bounds.extend(new maps.LatLng(announcement.lat, announcement.lng));
    });

    // Fit the map to the bounds
    map.fitBounds(bounds);

    const listener = maps.event.addListener(map, 'idle', () => {
      if (map.getZoom() > defaultProps.minZoom) {
        map.setZoom(defaultProps.minZoom);
      }
      maps.event.removeListener(listener);
    });
  };




  return (
    <div style={{ height: '50vh', width: '100%' }}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCI8O91FfQgfevadyNo9pKNt52MT5RZp_4" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapsRef.current = maps;
          handleApiLoaded(map, maps, announcements);
        }}
      >
        {announcements.map((announcement) => <DashboardIncidentMarker
          lat={announcement.lat}
          lng={announcement.lng}
          announcement={announcement}
        />)}
      </GoogleMapReact>
    </div>
  )
}

export default DashboardMap