import './App.css';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { theme } from './them';

import Teams from './pages/Teams';
import Dashboard from './pages/Dashboard';
import Announcement from './pages/Announcement';
import Incident from './pages/Incident';
import TeamForm from './components/Teams/TeamForm';
import TeamMemberList from './components/Teams/TeamMemberList';
import TeamMembers from './components/Teams/TeamMembers';
import CreateTeam from './components/Teams/CreateTeam';
import MethaneForm from './components/Announcement/MethaneForm';
import AnnouncementList from './components/Announcement/AnnouncementList';
import TeamList from './components/Teams/TeamList';
import TeamDetails from './components/Teams/TeamDetails';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/activation" element={<Announcement />} >
            <Route path="" index element={<AnnouncementList  title="Announcements"/>} />
            <Route path="announce" element={<MethaneForm simulate={false}/>} />
            <Route path="simulate" element={<MethaneForm simulate={true}/>} />
            <Route path="drills" index element={<AnnouncementList title="Drills"/>} />            
          </Route>
          <Route path="/active-incident/:id" element={<Incident />} /> 
          <Route path="/closed-incident/:id" element={<Incident />} />
          <Route path="/team" element={<Teams />}>
            <Route path="" index element={<TeamList/>} />
            <Route path="add" index element={<CreateTeam/>} />
            <Route path="members" element={<TeamMemberList />} />
            <Route path=":id" element={<TeamDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
