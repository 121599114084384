import React, { useEffect, useState } from 'react';
import { TextField, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio, MenuItem, Button, Grid, Container, Modal } from '@mui/material';
import { toast } from 'react-toastify';


import RoleService from '../../services/RoleService';
import TeamMemberService from '../../services/TeamMemberService';

const columns = [
    { id: 'fullname', label: 'Full Name' },
    { id: 'roleId', label: 'Role' },
    { id: 'mobile', label: 'Mobile' },
    { id: 'email', label: 'Email' },
    { id: 'address', label: 'Address' },
];

const TeamForm = ({ setOpen, loadMembers }) => {



    const [formData, setFormData] = useState({
        fullname: '',
        roleId: '',
        experience: '',
        gender: '',
        mobile: '',
        email: '',
        address: '',
        activityStatusId: 1,
        memberStatusId: 1,
        emirateId: ""
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await TeamMemberService.create(formData);
        if (!response.error) {
            toast.success(response.data.message);
            loadMembers();
            return;
        }
        toast.error("Something went wrong!");

    };

    // Roles
    const [roles, setRoles] = useState([]);
    const loadRoles = async () => {
        try {
            let response = await RoleService.findAll(1, 4);
            if (response && response.status === 200) {
                setRoles(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadRoles();
    }, []);

    return (
        <Container sx={{ width: "100%" }}>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    {columns.map((column) => (
                        <Grid item xs={12} key={column.id}>
                            {column.id === 'roleId' ? (
                                <TextField
                                    select
                                    fullWidth
                                    label={column.label}
                                    name={column.id}
                                    value={formData[column.id]}
                                    onChange={handleChange}
                                    variant="outlined"
                                >
                                    {roles.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.role_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : (
                                <TextField
                                    fullWidth
                                    label={column.label}
                                    name={column.id}
                                    type="text"
                                    value={formData[column.id]}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            )}
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Experience in Years"
                            name="experience"
                            type="number"
                            value={formData.experience}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Emirates Id"
                            name="emirateId"
                            type="text"
                            value={formData.emirateId}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup
                                row
                                aria-label="gender"
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="secondary" sx={{ marginTop: "10px" }}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default TeamForm;
