import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
const palette = ['yellow', 'blue', 'red', 'black', 'green'];

const size = {
    width: 300,
    height: 150,
};

export default function PieCharts({ label = "50 Patients", height=50, data, legend=false }) {
    return (
        <Box>           
            <Typography variant='h6'>{label}</Typography>
            <PieChart
                colors={palette}
                slotProps={{ legend: { hidden: legend } }}
                series={[{ data }]} height={height} />
        </Box>

    );
}
