import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Container, Button, Tooltip } from '@mui/material'

import CampaignIcon from '@mui/icons-material/Campaign';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import Sidebar from '../layouts/Sidebar'
import AnnouncementService from "../services/AnnouncementService"


function Announcement() {

    // Announcements
    const [announcements, setAnnouncements] = useState([]);
    const [drills, setDrills] = useState([]);
    const location = useLocation();

    const loadAnnouncements = async () => {
        try {
            let response = await AnnouncementService.findAll(1, 100);
            if (response && response.status === 200) {
                setDrills(response.data.filter(({drill})=>drill==true));
                setAnnouncements(response.data.filter(({drill})=>drill==false));
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadAnnouncements();
    }, [location.pathname]);

    const Header = () => {
        const navigate = useNavigate();
        return <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
                component="label"
                variant="text"
                color="secondary"
                startIcon={<CampaignIcon />}
                onClick={() => { navigate("drills");  }}
            >
                Drills
            </Button>
            <Button
                component="label"
                variant="text"
                color="secondary"
                startIcon={<NotificationImportantIcon />}
                onClick={() => { navigate("") }}
            >
                Announcements
            </Button>

        </div>
    }

    return (
        <Sidebar title="Announcements" header={<Header />}>
            <Container sx={{ marginTop: "10px" }}>
                <Outlet context={[announcements, drills]}/>
            </Container>
        </Sidebar>
    )
}

export default Announcement