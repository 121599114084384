import axios from "axios";
const { REACT_APP_REST_URL } = process.env;

const AnnouncementService = {

  async findAll(page, pageSize) {
    return await axios.get("https://cbrne.takniatech.ae/api/announcement", { params: { page, pageSize } });
  },
  async findById(announcementId) {
    return await axios.get(`https://cbrne.takniatech.ae/api/announcement/${announcementId}`);
  },
  async create(entity) {
    return await axios.post("https://cbrne.takniatech.ae/api/announcement", entity);
  }

}

export default AnnouncementService;
