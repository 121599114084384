

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Stack } from '@mui/material';
import TeamDialog from '../Teams/TeamDialog';

const AnnouncementsList = ({ announcements }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: "350px",
        height: '100vh',
        overflow: 'hidden',

      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{
          zIndex: 100,
          top: '10px',
          left: '190px',
        }}
      >
        Announcements
      </Button>
      {isOpen && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '350px',
            top: "50px",
            height: '80vh',
            backgroundColor: 'white',
            overflowY: 'auto',
            padding: '20px',
            zIndex: 100,
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box>
            <Stack sx={{ height: "90vh", overflow: "scroll" }}>
              {
                announcements && announcements.map((item) => {
                  return <Box
                    sx={{
                      marginBottom: "5px",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",


                    }}
                  >
                    <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                      Title: {item.title}
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      Location: {item.location}
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>

                      <TeamDialog announcementId={item.id} />
                      <Button variant="outlined" color="secondary" onClick={() => { navigate(`/active-incident/${item.id}`) }}>Reject</Button>
                      <Button variant="outlined" color="secondary" onClick={() => { navigate(`/active-incident/${item.id}`) }}>View</Button>
                    </div>
                  </Box>
                })
              }

            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AnnouncementsList;