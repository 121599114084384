import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';

import { orange, lightBlue, blue, green, purple, pink } from '@mui/material/colors';
import { Button } from '@mui/material';


import AnnouncementMemberService from '../../services/AnnouncementMemberService';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function TeamCard({ setTeams, teams=[], announcementId, assigned=true, handelViewTeam }) {
    console.log("teams process", teams)

    const ActivateAll = async (teamId) => {
        const data = await AnnouncementMemberService.activateAll(announcementId, teamId);

        if (data.error) {
            toast.error("Something went wrong!");
            return;
        }
        toast.success("Team Notified Successfully!");
        console.log("Team Activation Response", data);

        const filterTeams = teams.filter(({ id }) => teamId != id)
        setTeams(filterTeams);
    }

    return (

        <div>
            {
                teams.length > 0 && teams.map((item) => {
                    return <Card sx={{ display: 'flex', borderLeft: "10px solid", borderColor: orange[300], width: "90%", margin: '2px', justifyContent: "space-between", backgroundColor: lightBlue[100] }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                            <CardContent>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", width: '100%' }}>
                                        {/* <Avatar sx={{ bgcolor: orange[300] }}>{item?.teamName[0].toUpperCase()}</Avatar> */}
                                        <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                            {item?.name}
                                        </Typography>

                                    </Box>

                                    {/* {leader === item.id && (
                    <Tooltip title="Leader">
                        <PsychologyIcon sx={{ color: orange[700] }} />
                    </Tooltip>
                )} */}
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: '8px' }}>


                                    <StyledBadge badgeContent={item.admins} color="secondary">
                                        <Avatar sx={{ bgcolor: purple[300] }}>A</Avatar>
                                    </StyledBadge>

                                    <StyledBadge badgeContent={item.doctors} color="secondary">
                                        <Avatar sx={{ bgcolor: green[300] }}>D</Avatar>
                                    </StyledBadge>

                                    <StyledBadge badgeContent={item.rsos} color="secondary">
                                        <Avatar sx={{ bgcolor: pink[300] }}>R</Avatar>
                                    </StyledBadge>

                                    <StyledBadge badgeContent={item.nurses} color="secondary">
                                        <Avatar sx={{ bgcolor: orange[300] }}>N</Avatar>
                                    </StyledBadge>


                                    {/* <Typography variant="subtitle1" color="text.secondary">
                    {item?.activity_status?.status}
                </Typography> */}
                                </Box>

                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                    <Button variant='outlined' onClick={()=>handelViewTeam(item.id)}>View</Button>
                                    {!assigned && <Button variant='contained'>Locate</Button>}
                                    {!assigned && <Button variant='contained' onClick={() => { ActivateAll(item.id) }}>Assign</Button>}
                                </div>

                            </CardContent>
                        </Box>
                    </Card>
                })
            }
        </div>
    )
}

export default TeamCard