import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import TeamCard from '../Incident/TeamCard';
import TeamsService from '../../services/TeamsService';

import CreateTeamDialog from '../Dashboard/CreateTeamModal';
import { processTeamData } from '../../utils/utils';

const emails = ['username@gmail.com', 'user02@gmail.com'];


function SimpleDialog(props) {
    const { onClose, selectedValue, open, announcementId, teams, setTeams, handleClickOpen } = props;
    const navigate = useNavigate()

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        navigate("/team/add")
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle style={{ display: "flex", justifyContent: "space-between", minWidth:400 }}>
                <div>{teams.length > 0 ? teams.length : "No"} Teams</div>
                <Button variant="contained" onClick={onClose}>Done</Button>
            </DialogTitle>


            <CreateTeamDialog handleClickOpenTeamModal={handleClickOpen}/>

            <TeamCard assigned={false} teams={teams} setTeams={setTeams} announcementId={announcementId} />

        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

const TeamDialog = ({ announcementId }) => {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(emails[1]);
    const [teams, setTeams] = useState([]);

    const handleClickOpen = async () => {
        const response = await TeamsService.findAll()

        if (response.data.error) {
            toast.error("Something went wrong!")
            return;
        }
        console.log('teams', response.data)
        setTeams(() => processTeamData(response.data))
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            {/* <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography> */}
            {/* <br /> */}
            <Button variant="outlined" onClick={handleClickOpen}>
                Activate
            </Button>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                announcementId={announcementId}
                teams={teams}
                setTeams={setTeams}
                handleClickOpen={handleClickOpen}
            />
        </div>
    );
}

export default TeamDialog;
