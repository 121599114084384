import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { teamCoding } from '../../them';
import { orange, lightBlue, green } from '@mui/material/colors';

const backgroundColor = (status) => {
    switch (status) {
        case "Active":
            return green[50];
        case "Partially Active":
            return orange[50];
        case "In Active":
            return lightBlue[50];
        default:
            return lightBlue[50]; // default case if status doesn't match any known values
    }
};

const TeamCard = ({ item, index }) => {
    const [contextMenu, setContextMenu] = useState(null);
    const [leader, setLeader] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
            }
            : null
        );
    };

    const handleClose = (id) => {
        setLeader(leader ? null : id);
        setContextMenu(null);
    };

    return (
        <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onContextMenu={handleContextMenu}
                    style={{ cursor: 'context-menu', ...provided.draggableProps.style }}
                >
                    <Card sx={{ display: 'flex', borderLeft: "10px solid", borderColor: teamCoding(item?.role?.role_name), width: "90%", margin: '2px', justifyContent: "center", backgroundColor: backgroundColor(item?.activity_status?.status) }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", width: '90%' }}>
                                        <Avatar sx={{ bgcolor: orange[700] }}>{item?.role?.role_name[0].toUpperCase()}</Avatar>
                                        <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                            {item?.fullname}
                                        </Typography>
                                    </Box>
                                    {leader === item.id && (
                                        <Tooltip title="Leader">
                                            <PsychologyIcon sx={{ color: orange[700] }} />
                                        </Tooltip>
                                    )}
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "210px", marginTop: '8px' }}>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        {item?.experience}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        {item?.activity_status?.status}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Box>
                    </Card>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
                    >
                        <MenuItem onClick={() => handleClose(item.id)}>
                            {leader === item.id ? "Remove Leader" : "Make Leader"}
                        </MenuItem>
                    </Menu>
                </div>
            )}
        </Draggable>
    );
};

export default TeamCard;
