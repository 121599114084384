import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';

import { orange, lightBlue, blue, green, purple, pink } from '@mui/material/colors';
import { Button } from '@mui/material';

function MemberCard({ members }) {
    return (
        <div>
            <div style={{ width: "100%" }}>
                <Button>Whole Team</Button>
                <Button>Admins</Button>
                <Button>RSOS</Button>
                <Button>Nurses</Button>
                <Button>Doctors</Button>
            </div>
            {
                members.length > 0 && members.map((item) => {
                    return <Card sx={{ display: 'flex', borderLeft: "10px solid", borderColor: orange[300], width: "90%", margin: '2px', justifyContent: "space-between", backgroundColor: lightBlue[100] }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                            <CardContent>
                                <Box style={{ lineHeight: "2px" }}>
                                    <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                        Name: {item?.member.fullname}
                                    </Typography>
                                    <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                        Accepted: {item?.accepted ? "Yes" : "No"}
                                    </Typography>
                                    <br />
                                    <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                        Mobile: {item?.member?.mobile}
                                    </Typography>
                                    <br />
                                    <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                        Role: {item?.member?.role.role_name}
                                    </Typography>
                                    <br />
                                    <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                        ETA: {item?.eta || "10 minutes"}
                                    </Typography>
                                    <Typography component="div" variant="body1" sx={{ marginLeft: '8px' }}>
                                        Distance: {item?.eta || "15 km"}
                                    </Typography>
                                    {/* {leader === item.id && (
            <Tooltip title="Leader">
                <PsychologyIcon sx={{ color: orange[700] }} />
            </Tooltip>
        )} */}
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginTop: '8px' }}>



                                </Box>

                                {/* <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                    <Button variant='outlined' onClick={() => handelViewTeam(item.id)}>View</Button>
                                    {assigned && <Button variant='contained'>Locate</Button>}
                                    {assigned && <Button variant='contained' onClick={() => { ActivateAll(item.id) }}>Assign</Button>}
                                </div> */}

                            </CardContent>
                        </Box>
                    </Card>
                })
            }
        </div>
    )
}

export default MemberCard