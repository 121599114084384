import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
//   yAxis: [
//     {
//       label: 'rainfall (mm)',
//     },
//   ],
  width: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};
const dataset = [
  {
    responded: 59,
    onTheWay: 57,
    declined: 86,
    atScene: 21,
    category: 'Doctors',
  },
  {
    responded: 50,
    onTheWay: 52,
    declined: 78,
    atScene: 28,
    category: 'Nurses',
  },
  {
    responded: 47,
    onTheWay: 53,
    declined: 106,
    atScene: 41,
    category: 'RSOs',
  },
  {
    responded: 54,
    onTheWay: 56,
    declined: 92,
    atScene: 73,
    category: 'Admins',
  }
];

const valueFormatter = (value) => `${value}mm`;

export default function () {
  return (
    <BarChart
      dataset={dataset}
      xAxis={[{ scaleType: 'band', dataKey: 'category' }]}
      series={[
        { dataKey: 'responded', label: 'Responded'},
        { dataKey: 'onTheWay', label: 'On The Way' },
        { dataKey: 'declined', label: 'Declined' },
        { dataKey: 'atScene', label: 'At Scene' },
      ]}
      {...chartSetting}
    />
  );
}
