import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Card, Popover, IconButton } from '@mui/material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { pink } from '@mui/material/colors';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const DashboardIncidentMarker = ({ lng, lat, announcement }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handlePopoverOpen = (event) => {
        if (!isOpen) {
            setAnchorEl(event.currentTarget);
            setIsOpen(true);
            return;
        }
        setAnchorEl(null);
        setIsOpen(false);


    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return <div>
        <IconButton
        href={`/active-incident/${announcement?.id}`}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverOpen}
        >
            <DangerousIcon fontSize="large" sx={{ color: pink[500] }} />
        </IconButton>

        <Popover
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
        >
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography variant='h6'>
                        Id:{announcement?.id}
                    </Typography>
                    <Typography variant='h6'>
                        {announcement?.isMajor ? "Major" : "Not Major"}
                    </Typography>
                    <Typography variant='h6'>
                        Description: {announcement?.description}
                    </Typography>
                    <Typography >
                        Location: {announcement?.location}
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small" >Learn More</Button>
                </CardActions> */}
            </Card>
        </Popover></div>
};

export default DashboardIncidentMarker