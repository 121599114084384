import * as React from 'react';
import { useNavigate } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button  from '@mui/material/Button';
import AnnouncementsList from './AnnouncementsList';

import AttractionsIcon from '@mui/icons-material/Attractions';

const Header = () => {

    const [currentIncident, setCurrentIncident] = React.useState('');
    const [closedIncident, setClosedIncident] = React.useState('');

    const navigate = useNavigate();

    const handleCurrentChange = (event) => {
        setCurrentIncident(event.target.value);
        navigate(`/active-incident/${event.target.value}`);
    };

    const handleClosedChange = (event) => {
        setClosedIncident(event.target.value);
        navigate(`/closed-incident/${event.target.value}`);
    };

    return <div style={{ display: "flex", justifyContent: "space-between" }}>
        
        <Button variant="text" startIcon={<AttractionsIcon />} color="secondary" href="/dashboard">Resources</Button>
        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small-label">Active Incidents</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={currentIncident}
                label="Active Incidents"
                onChange={handleCurrentChange}
            >

                <MenuItem value={10}>Incident A</MenuItem>
                <MenuItem value={20}>Incident B</MenuItem>
                <MenuItem value={30}>Incident C</MenuItem>
            </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small-label">Closed Incidents</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={closedIncident}
                label="Active Incidents"
                onChange={handleClosedChange}
            >

                <MenuItem value={10}>Incident A</MenuItem>
                <MenuItem value={20}>Incident B</MenuItem>
                <MenuItem value={30}>Incident C</MenuItem>
            </Select>
        </FormControl>

        

    </div>
}

export default Header;