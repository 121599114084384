import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
//   yAxis: [
//     {
//       label: 'rainfall (mm)',
//     },
//   ],
  width: 300,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};
const dataset = [
  {
    responded: 59,
    onTheWay: 57,
    declined: 86,
    atScene: 21,
    category: 'Doctors',
  }
];

const valueFormatter = (value) => `${value}mm`;



export default function BarCharts ({data}) {

  return (
    <BarChart
      dataset={dataset}
      xAxis={[{ scaleType: 'band', dataKey: 'category' }]}
      series={[
        { dataKey: 'responded' },
        { dataKey: 'onTheWay' },
        { dataKey: 'declined'},
        { dataKey: 'atScene'},
      ]}
      {...chartSetting}
    />
  );
}
