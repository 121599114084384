import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Groups2Icon from '@mui/icons-material/Groups2';
import CampaignIcon from '@mui/icons-material/Campaign';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { IconButton, Container, Tooltip, Button } from '@mui/material'


import Sidebar from '../../layouts/Sidebar'
import FormDialog from '../shared/FormDialog';
import TeamForm from './TeamForm';
import TeamMembers from './TeamMembers'
import CreateTeam from './CreateTeam'
import { columnsFromBackend } from '../../mock/team'

import TeamMemberList from './TeamMemberList';
import CustomizableTable from '../shared/CustomizableTable';



function TeamDetails() {

    const [open, setOpen] = useState(false);
    const Header = () => {


        const navigate = useNavigate();
        return <div >
            <Button
                component="label"
                variant="text"
                color="secondary"               
                startIcon={<Diversity3Icon/>}
                onClick={() => navigate("")}
            >
                TeamDetails
            </Button>
            <Button
                component="label"
                variant="text"
                color="secondary"
                startIcon={<PeopleAltIcon />}
                onClick={() => navigate("members")}
            >
                Members
            </Button>
        </div>
    }

    return (
        <Sidebar title="CBRNE EMS TEAM" header={<Header />}>

            <Outlet />

        </Sidebar>
    )
}

export default TeamDetails