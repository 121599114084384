import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Tooltip from '@mui/material/Tooltip';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


import CustomizableTable from '../shared/CustomizableTable';
import TeamsService from "../../services/TeamsService"
import FormDialog from '../shared/FormDialog';
import TeamForm from './TeamForm'

import AnnouncementMemberService from '../../services/AnnouncementMemberService';


import { processTeamData } from '../../utils/utils';


function TeamList() {

    const navigate = useNavigate();

    // Teams
    const [teams, setTeams] = useState([]);
    const loadTeams = async () => {
        try {
            let response = await TeamsService.findAll(1, 100);
            if (response && response.status === 200) {

                const data = processTeamData(response.data)
                console.log(data)
                setTeams(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadTeams();
    }, []);

    const headCells = [
        {
            id: 'teamName',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'doctors',
            numeric: true,
            disablePadding: false,
            label: 'Doctors',
        },
        {
            id: 'admins',
            numeric: true,
            disablePadding: false,
            label: 'Admins',
        },
        {
            id: 'nurses',
            numeric: true,
            disablePadding: false,
            label: 'Nurses',
        },
        {
            id: 'rsos',
            numeric: true,
            disablePadding: false,
            label: 'RSOs',
        },

    ];

    //activate team
    const [message, setMessage] = useState('');

    const handleActivate = async () => {
        try {
            const teamId = 1; 
            const announcementId = 1; 
            const response = await AnnouncementMemberService.activate(teamId, announcementId);
            toast.success(response.message)
        } catch (error) {
            toast.error(error.message)
        } 
    };

    const handleActivateDrill = () => {
        console.log("activate drill")
    }

    const options = [
        {
            title: 'Activate Announcement',
            handler: handleActivate
        },
        {
            title: 'Activate Drill',
            handler: handleActivateDrill
        }

    ];

    return (
        <div style={{ margin: "30px" }}>
            <Box sx={{ display: "flex", width: "100vw", rowGap: "20px", margin: "10px", alignItems: 'center' }}>
                <Tooltip title="Add Member">
                    <IconButton onClick={() => navigate("add")}>
                        <AddBoxOutlinedIcon color="primary" fontSize="large" />
                    </IconButton>
                </Tooltip>
                <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    variant="standard"
                    color="primary"
                    size="large"
                />
            </Box>


            <CustomizableTable title="EMS Teams" rows={teams} headCells={headCells} moreOptions={options} />
        </div>
    )
}

export default TeamList