import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Sidebar from '../layouts/Sidebar';
import PieCharts from '../components/Charts/PieChart';
import TeamStatus from '../components/Incident/TeamStatus';
import IncidentMap from '../components/Incident/IncidentMap';
import METHANE from '../components/Incident/METHANE';
import QuickActions from '../components/Incident/QuickActions';
import BarCharts from '../components/Charts/BarCharts';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Header from '../components/Dashboard/Header';

import AnnouncementService from '../services/AnnouncementService';
import AnnouncementMemberService from '../services/AnnouncementMemberService';
import TeamCard from '../components/Incident/TeamCard';
import MemberCard from '../components/Teams/MemberCard';
import { processTeamData } from '../utils/utils';

const patientData = [
    { value: 5, label: 'Warm' },
    { value: 10, label: 'Hospital' },
    { value: 15, label: 'Red Zone' },
    { value: 20, label: 'Died' },
];

const ambulanceData = [
    { value: 5, label: 'At Scene' },
    { value: 10, label: 'On the Way' },
    { value: 10, label: 'On Stand By' },
];

const dataset = [
    {
        utilized: 59,
        available: 57,
        category: 'A',
    },
    {
        utilized: 59,
        available: 57,
        category: 'B',
    },
    {
        utilized: 59,
        available: 57,
        category: 'C',
    },
    {
        utilized: 59,
        available: 57,
        category: 'D',
    },
];

function Incident() {
    let { id } = useParams();
    const [announcement, setAnnouncement] = useState({});
    const [members, setMembers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [assignedTeams, setAssignedTeams] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [action, setAction] = useState("assignTeam");

    const loadMembers = async () => {
        const response = await AnnouncementMemberService.findById(id);

        if (response.data.error) {
            toast.error("Something went wrong!");
            return;
        }
        console.log("members", response.data.entity);
        setMembers(response.data.entity);

        // Extract unique teams
        const uniqueTeams = response.data.entity
            .map(item => item.team)
            .filter((team, index, self) =>
                index === self.findIndex(t => t.id === team.id)
            );

        // Process each team to get the required format
        const processedTeams = uniqueTeams.map(team => {
            // Filter members belonging to the current team
            const teamMembers = response.data.entity.filter(item => item.team.id === team.id);

            // Count total members and members by role
            const roleCounts = teamMembers.reduce((acc, item) => {
                acc.total += 1;
                const role = item.member.role.role_name.toLowerCase();
                if (acc[role]) {
                    acc[role] += 1;
                } else {
                    acc[role] = 1;
                }
                return acc;
            }, { total: 0 });

            return {
                id: team.id,
                name: team.name,
                totalMembers: roleCounts.total,
                admins: roleCounts.admins || 0,
                doctors: roleCounts.doctors || 0,
                nurses: roleCounts.nurses || 0,
                rsos: roleCounts.rsos || 0
            };
        });

        console.log("processed members", processedTeams);
        setAssignedTeams(processedTeams)
    };

    const loadAnnouncement = async () => {
        const data = await AnnouncementService.findById(id);

        if (data.data.error) {
            toast.error("Something went wrong!");
            return;
        }
        setAnnouncement(data.data);

    };

    const handelViewTeam = (id) => {
        const data = members.filter((item) => item.teamId == id);
        setTeamMembers(data)
        setAction("viewMembers")
    }

    // useEffect(() => {
    //     loadAnnouncement();
    //     loadMembers();
    // }, [id]);

    

    useEffect(() => {
        // Load announcements initially
        loadAnnouncement();
        loadMembers();

        // Set up a 30-second interval to load announcements
        const intervalId = setInterval(() => {
            console.log("updating every 30 secs");
            loadAnnouncement();
            loadMembers();
        }, 30000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [id]);




    return (
        <Sidebar title={`${announcement.drill ? "Drill" : "Announcement"}- ${announcement.title}`} header={<Header />}>
            {announcement && (
                <Grid container columnSpacing={1} sx={{ padding: 0, marginTop: "10px" }}>
                    <Grid item sm={3}>
                        <METHANE announcement={announcement} />
                    </Grid>
                    <Grid item sm={6}>
                        {/* <Box display="flex" style={{ height: "33vh" }}>
                            <BarCharts />
                            <TeamStatus />
                            <PieCharts label="50 Patients" data={patientData} />
                            <TeamStats label="RSOs" />
                            <TeamStats label="Admins" />
                            <TeamStats label="Doctors" />
                            <TeamStats label="Nurses" legend={false} />
                        </Box> */}
                        <IncidentMap lat={announcement.lat} lng={announcement.lng} members={members} />
                    </Grid>
                    <Grid item sm={3}>
                        <Box style={{ height: "87vh", overflowY: "auto" }}>
                            <Stack>
                                {action == "viewTeam" && teams && <TeamCard assigned={false} teams={teams} setTeams={setTeams} announcementId={id} handelViewTeam={handelViewTeam} />}
                                {
                                    action == "assignTeam" && members && assignedTeams && <TeamCard assigned={true} teams={assignedTeams} setTeams={setAssignedTeams} announcementId={id} handelViewTeam={handelViewTeam} />
                                }
                                {
                                    action == "viewMembers" && assignedTeams && <MemberCard members={teamMembers} />
                                }
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            )}
            {announcement && <QuickActions setTeams={setTeams} announcementId={id} setAction={setAction} />}
        </Sidebar>
    );
}

export default Incident;
