import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Divider, TextField, Typography, Grid, Paper, Button, Box } from '@mui/material';
import { toast } from 'react-toastify';


import { teamCoding } from '../../them';
import TeamCard from './TeamCard';
import TeamsService from '../../services/TeamsService';
import TeamMemberService from "../../services/TeamMemberService"

let columnsFromBackend = {}

const CreateTeam = () => {


    const loadMembers = async () => {
        try {
            let response = await TeamMemberService.findAll(1, 100);
            if (response && response.status == "200") {
                columnsFromBackend = {
                    "teams": {
                        title: 'Team Members',
                        items: response?.data || [],
                    },
                    "doctors": {
                        title: 'Doctorss',
                        items: [],
                    },
                    "admins": {
                        title: 'Admins',
                        items: [],
                    },
                    "rsos": {
                        title: 'RSOs',
                        items: [],
                    },
                    "nurses": {
                        title: 'Nursesss',
                        items: [],
                    },
                };
                setColumns(columnsFromBackend);


            }
        } catch (error) { console.log(error) }

    }

    useEffect(() => { loadMembers() }, []);

    const [columns, setColumns] = useState([]);
    const [teamMembers, setTeamMembers] = useState([])
    const [name, setName] = useState("");
    const [doctorLeader, setDoctorLeader] = useState();
    const [adminLeader, setAdminLeader] = useState()

    const onDragEnd = (result, columns, setColumns) => {
        console.log(result.destination.droppableId)

        if (result.destination.droppableId !== "teams") {
            setTeamMembers((prev) => [...new Set([...prev, result.draggableId])])
            console.log(teamMembers)
        } else {
            setTeamMembers((prev) => prev.filter((item) => item != result.draggableId))

        }
        console.log(teamMembers)

        if (!result.destination) return;


        const { source, destination } = result;



        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });



        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });

        }

        console.log("cols", columns)


    };

    const handleSubmit = async () => {

        if(!name || teamMembers.length ==0 ){
            toast.info("Please add team name and members!");
            return;
        }
        const response = await TeamsService.create({
            name, members: teamMembers
        });

        if (response && !response.data.error) {
            setName("")
            setColumns(columnsFromBackend);
            setTeamMembers([])
            toast.success(response.data.message);
            return 
        }
        toast.error("Something went wrong!");
    }
    return (
        <Box>
            <div style={{ paddingTop: 20 }}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            label="Team Name"
                            name="name"
                            value={name}
                            onChange={({ target }) => { setName(target.value) }}
                            variant="outlined"
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            fullWidth
                            select
                            label="Incident"
                            name="incident"
                            // value={formData[column.id]}
                            // onChange={handleChange}
                            variant="outlined"
                        >
                            {[].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            component="label"
                            variant="contained"
                            color="secondary"
                            sx={{ padding: "12px" }}
                            onClick={handleSubmit}
                        >
                            Create Team
                        </Button>
                    </Grid>
                </Grid>
            </div>


            <DragDropContext
                onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
                <Box display="flex" flexWrap="wrap">

                    {Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <Droppable key={columnId} droppableId={columnId}>
                                {(provided, snapshot) => (
                                    <Paper
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        key={index}
                                        flex="1 0 15%" // Flex grow, flex shrink, and flex basis for equal width
                                        // Ensure columns don't exceed 20% width

                                        sx={{ height: "85vh", marginY: "10px", width: "20%" }}
                                    >
                                        {/* <Box display="flex">
                                        <Badge color="secondary" badgeContent={1} anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }} sx={{margin:"10px"}}>A</Badge>
                                        <Badge color="secondary" badgeContent={10} anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}  sx={{margin:"10px"}}>P</Badge>
                                        <Badge color="secondary" badgeContent={10} anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}  sx={{margin:"10px"}}>I</Badge>
                                    </Box> */}
                                        <Box sx={{ background: teamCoding(columnId), display: "flex", justifyContent: "center", paddingY: "5px" }}>
                                            <Typography variant="subtitle1" color="text.secondary" component="h1">{column.title}</Typography>
                                        </Box>
                                        <Divider />
                                        <Paper sx={{ overflow: "auto", height: "100%" }}>
                                            {column.items.map((item, index) => (
                                                <TeamCard key={item.id} item={item} index={index} />
                                            ))}
                                        </Paper>

                                        {provided.placeholder}
                                    </Paper>
                                )}
                            </Droppable>
                        );
                    })}

                </Box>
            </DragDropContext>
        </Box>
    );
};

export default CreateTeam;
