import axios from "axios";
const { REACT_APP_REST_URL } = process.env;

const AnnouncementMemberService = {

    async activateAll(announcementId, teamId) {
        try {
            console.log("team", {teamId, announcementId})
            const response = await axios.post(`https://cbrne.takniatech.ae/api/announcement-member/activate`, {teamId, announcementId});
            return response.data;
        } catch (error) {
            console.error('Error activating announcement member:', error);
            throw error;
        }
    },
    async findById(announcementId) {
        return await axios.get(`https://cbrne.takniatech.ae/api/announcement-member/get-by-announcement/${announcementId}`);
    },

}

export default AnnouncementMemberService;