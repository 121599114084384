import axios from "axios";
const { REACT_APP_REST_URL } = process.env;

const TeamsService = {

  async findAll(page, pageSize) {
    return await axios.get("https://cbrne.takniatech.ae/api/team", { params: { page:1, pageSize:100 } });
  },

  async create(entity) {
    return await axios.post("https://cbrne.takniatech.ae/api/team", entity);
  },

  async update(entity) {
    return await axios.put(REACT_APP_REST_URL + "/team/" + entity.id, entity);
  },


  async archive(id) {
    return await axios.get(REACT_APP_REST_URL + "/team/archive", { params: { id, archived_by: localStorage.getItem("id") } });
  },

}

export default TeamsService;
