import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { toast } from 'react-toastify';

import Diversity1Icon from '@mui/icons-material/Diversity1';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import TeamCard from './TeamCard';
import { processTeamData } from '../../utils/utils';

import TeamsService from '../../services/TeamsService';


export default function QuickActions({ setTeams, setAction }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleAssignTeam = async () => {

    const response = await TeamsService.findAll()

    if (response.data.error) {
      toast.error("Something went wrong!")
      return;
    }
    console.log('teams', response.data)
    setTeams(() => processTeamData(response.data))
    setAction("viewTeam")

  }

  const handleViewTeam = async () => {
    setAction("assignTeam");

  }

 

  const actions = [
    { icon: <AccountBalanceIcon />, name: 'Involve Stakeholders', handler: handleAssignTeam },
    { icon: <LocalHospitalIcon />, name: 'Prepare Hospital', handler: handleAssignTeam },
    { icon: <LocalShippingIcon />, name: 'Assign Ambulance', handler: handleAssignTeam },
    { icon: <Diversity1Icon />, name: 'Assign Team', handler: handleAssignTeam },
    { icon: <Diversity1Icon />, name: 'View Teams', handler: handleViewTeam },
  ];

  return (
    <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
      {/* <Backdrop open={open} /> */}
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.handler}
          />
        ))}
      </SpeedDial>
    </Box>

  );
}
