export function processTeamData(teams) {
    return teams.map(team => {
        const membersCount = team.members.length;
        const roleCounts = team.members.reduce((acc, member) => {
            const roleName = member.role.role_name;
            if (!acc[roleName]) {
                acc[roleName] = 0;
            }
            acc[roleName]++;
            return acc;
        }, { doctors: 0, nurses: 0, rsos: 0 });

        return {
            id:team.id,
            name: team.name,
            totalMembers: membersCount,
            admins: roleCounts.admins || 0,
            doctors: roleCounts.doctors || 0,
            nurses: roleCounts.nurses || 0,
            rsos: roleCounts.rsos || 0
        };
    });
}