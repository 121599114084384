import React, { useState } from 'react'
import { Card, Popover, IconButton, CardContent, Typography } from '@mui/material';
import { pink, green, orange, purple } from '@mui/material/colors';

import DangerousIcon from '@mui/icons-material/Dangerous';
import PushPinIcon from '@mui/icons-material/PushPin';


const IncidentMarker = ({ name = "Sadia", distance = "2", team = "brown", showDetails = false, role = "incident" }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handlePopoverOpen = (event) => {
        if (!isOpen && showDetails) {
            setAnchorEl(event.currentTarget);
            setIsOpen(true);
            return;
        }
        setAnchorEl(null);
        setIsOpen(false);


    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return <div >
        <IconButton
            onMouseOver={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            {role == "incident" && <DangerousIcon fontSize="large" sx={{ color: pink[500] }} />}
            {role == "rsos" && <PushPinIcon fontSize="large" sx={{ color: pink[500] }} />}
            {role == "nurses" && <PushPinIcon fontSize="large" sx={{ color: orange[500] }} />}
            {role == "doctors" && <PushPinIcon fontSize="large" sx={{ color: green[500] }} />}
            {role == "admins" && <PushPinIcon fontSize="large" sx={{ color: purple[500] }} />}
        </IconButton>

        <Popover
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            style={{zIndex:1000}}
            disableRestoreFocus
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
        >
            <Card sx={{ minWidth: 230 }}>
                <CardContent>
                    <Typography>
                        Name: {name}
                    </Typography>
                    <Typography>
                        Role: {role}
                    </Typography>
                    <Typography>
                        Team: {team}
                    </Typography>
                    <Typography >
                        Distance: {distance}km
                    </Typography>
                </CardContent>
            </Card>
        </Popover></div>
};

export default IncidentMarker