import axios from "axios";
const { REACT_APP_REST_URL } = process.env;

const IncidentTypeService = {

  async findAll(page, pageSize) {
    return await axios.get("https://cbrne.takniatech.ae/api/incident-type", { params: { page, pageSize } });
  }

}

export default IncidentTypeService;
