import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Groups2Icon from '@mui/icons-material/Groups2';
import CampaignIcon from '@mui/icons-material/Campaign';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { IconButton, Container, Tooltip, Button } from '@mui/material'


import Sidebar from '../layouts/Sidebar'
import FormDialog from '../components/shared/FormDialog';
import TeamForm from '../components/Teams/TeamForm';
import TeamMembers from '../components/Teams/TeamMembers'
import CreateTeam from '../components/Teams/CreateTeam'
import { columnsFromBackend } from '../mock/team'

import TeamMemberList from '../components/Teams/TeamMemberList';
import CustomizableTable from '../components/shared/CustomizableTable';



function Teams() {

    const [open, setOpen] = useState(false);
    const Header = () => {


        const navigate = useNavigate();
        return <div >
            <Button
                component="label"
                variant="text"
                color="secondary"
                startIcon={<Diversity3Icon />}
                onClick={() => navigate("")}
            >
                Teams
            </Button>
            <Button
                component="label"
                variant="text"
                color="secondary"
                startIcon={<PeopleAltIcon />}
                onClick={() => navigate("members")}
            >
                Members
            </Button>
        </div>
    }

 

    return (
        <Sidebar title="CBRNE EMS TEAM" header={<Header />}>

            <Outlet />

        </Sidebar>
    )
}

export default Teams