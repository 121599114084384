import React, { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import IncidentMarker from './IncidentMarker';

const defaultProps = {
    center: {
        lat: 24.491241,
        lng: 54.37831961,
    },
    zoom: 15,
};

function IncidentMap({ lat = 24.491241, lng = 54.37831961, details = '', members = [] }) {

    const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: 24.491241,
            lng: 54.37831961,
        },
        zoom: 13,
        minZoom: 13 // Set a minimum zoom level
    });
    const mapRef = useRef(null);
    const mapsRef = useRef(null);

    // useEffect(() => {
    //     setCenter({ lat, lng });
    //     setZoom(defaultProps.zoom);
    // }, [lat, lng]);

    const handleApiLoaded = (map, maps) => {
        const bounds = new maps.LatLngBounds();
        // Extend bounds to include the initial marker
        bounds.extend(new maps.LatLng(lat, lng));

        // Extend bounds to include additional members' markers
        members.forEach(({ lat, lng }) => {
            lat && lng && bounds.extend(new maps.LatLng(lat, lng));
        });

        map.fitBounds(bounds);

        const listener = maps.event.addListener(map, 'idle', () => {
            if (map.getZoom() > 10) {
                map.setZoom(12);
            }
            maps.event.removeListener(listener);
        });
    };

    return (
        <div style={{ height: '90vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCI8O91FfQgfevadyNo9pKNt52MT5RZp_4' }}
                defaultCenter={{
                    lat: 24.491241,
                    lng: 54.37831961,
                }}
                defaultZoom={13}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                    // mapRef.current = map;
                    // mapsRef.current = maps;
                    handleApiLoaded(map, maps)
                }}
            >
                <IncidentMarker
                    lat={lat}
                    lng={lng}
                    text={details}
                />
                {members.map(({ lat, lng, member, accepted, team }, index) => (
                    accepted && <IncidentMarker
                        key={index}
                        lat={lat}
                        lng={lng}
                        role={member.role.role_name}
                        name={member.fullname}
                        team={team.name}
                        showDetails={true}
                    // text={member.details}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
}

export default IncidentMap;
