
import { createTheme } from '@mui/material/styles';
import { pink, purple, green, orange, lightBlue } from '@mui/material/colors';



// Create a custom theme
export const theme = createTheme({
    palette: {
        primary: {
            main: lightBlue[700],
        },
        secondary: {
            main: orange[700],
            contrastText: '#fffff',
        }
    },
    typography: {
        button: {
            textTransform: 'none', // Remove uppercase transformation
            fontWeight: 'bold', // Make the font bold
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8, // Customize the border radius
                    padding: '8px 16px', // Customize the padding
                    
                },
                containedPrimary: {
                    backgroundColor: orange[700],
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#1565c0', // Customize hover color for primary buttons
                    },
                },
                containedSecondary: {
                    backgroundColor: orange[700],
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: orange[800], // Customize hover color for secondary buttons
                    },
                },
                outlinedPrimary: {
                    borderColor: '#1976d2',
                    color: '#1976d2',
                    '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.08)', // Customize hover background color for outlined primary buttons
                    },
                },
                outlinedSecondary: {
                    borderColor: '#fffff',
                    color: '#fffff',
                    '&:hover': {
                        backgroundColor: 'rgba(220, 0, 78, 0.08)', // Customize hover background color for outlined secondary buttons
                    },
                },
                textPrimary:{

                },
                textSecondary:{
                    color: '#ffffff',
                    '&:hover': {
                        color: orange[700], // Customize hover color for secondary buttons
                    },
                }
            },
        },
    },
});


export const teamCoding = (status, intensity = 300) => {

    switch (status) {
        case "doctors":
            return green[intensity]
        case "nurses":
            return orange[intensity]
        case "rsos":
            return pink[intensity]
        case "teams":
            return lightBlue[intensity]
        default:
            return purple[intensity]
    }
}