import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '@mui/material';
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import Header from '../components/Dashboard/Header'
import Sidebar from '../layouts/Sidebar'
import PieCharts from '../components/Charts/PieChart'
import TeamStatus from '../components/Incident/TeamStatus'
import DashboardMap from '../components/Dashboard/DashboardMap';

import METHANE from '../components/Incident/METHANE'
import BarCharts from '../components/Charts/BarCharts'
import ConsumptionChart from '../components/Charts/ConsumptionChart';

import AnnouncementService from '../services/AnnouncementService';
import Diversity1 from '@mui/icons-material/Diversity1';
import { Typography } from '@mui/material';
import Announcements from '../components/Dashboard/AnnouncementsList';
import AnnouncementsList from '../components/Dashboard/AnnouncementsList';






const patientData = [
    { value: 5, label: 'Discharged' },
    { value: 10, label: 'Referer' },
    { value: 15, label: 'Isolated' },
    // { value: 20, label: 'Died' },
];

const ambulanceData = [
    { value: 5, label: 'C' },
    { value: 10, label: 'B' },
    { value: 10, label: 'R' },
    { value: 12, label: 'N' },
    { value: 10, label: 'E' },
];

const dataset = [
    {
        utilized: 59,
        available: 57,
        category: 'A',
    },
    {
        utilized: 59,
        available: 57,
        category: 'B',
    },
    {
        utilized: 59,
        available: 57,
        category: 'C',
    },
    {
        utilized: 59,
        available: 57,
        category: 'D',
    },
];

const ppeDataset = [
    {
        available: 59,
        utilised: 57,
        category: 'A',
    },
    {
        available: 29,
        utilised: 100,
        category: 'B',
    },
    {
        available: 59,
        utilised: 17,
        category: 'C',
    },
    {
        available: 29,
        utilised: 57,
        category: 'D',
    },
];

const ppeSeries = [
    { dataKey: 'available', label: "PPE Available" },
    { dataKey: 'utilised', label: "PPE Utilised" },
]

const teamDataset = [
    {
        active: 59,
        partiallyActive: 57,
        inActive: 10,
        category: "Admins"
    },
    {
        active: 59,
        partiallyActive: 57,
        inActive: 10,
        category: "Doctors"
    },
    {
        active: 59,
        partiallyActive: 57,
        inActive: 15,
        category: "Nurses"
    },
    {
        active: 59,
        partiallyActive: 57,
        inActive: 30,
        category: "RSOs"
    },
];
const teamSeries = [
    { dataKey: 'active', label: "Active" },
    { dataKey: 'partiallyActive', label: "Partially Active" },
    { dataKey: 'inActive', label: "Inactive" },
]

const ppeAmbulances = [
    {
        available: 59,
        utilised: 57,
        category: 'Ambulances',
    },

];



function Dashboard() {

    const [announcements, setAnnouncements] = useState([]);

    const loadAnnouncements = async () => {
        try {
            let response = await AnnouncementService.findAll(1, 100);
            if (response && response.status === 200) {
                setAnnouncements(response.data)
                console.log("announcements", response.data);
                if (response.data.length > announcements.length)
                    toast.info("New announcement:" + response.data[response.data.length - 1].title)
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Load announcements initially
        loadAnnouncements();

        // Set up a 30-second interval to load announcements
        const intervalId = setInterval(() => {
            loadAnnouncements();
        }, 30000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [loadAnnouncements]);

    const navigate = useNavigate()


    {/* <ConsumptionChart data={teamDataset} series={teamSeries} height={200} /> */ }
    return (
        <Sidebar title="DASHBOARD" header={<Header />}>


            <div >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <AnnouncementsList announcements={announcements}/>
                </div>

                <Grid container spacing={1} sx={{ height: "45vh" }}>

                    {/* <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                height: '100%',
                                // backgroundColor: 'red',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white'
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    width: '95%',
                                    padding: '10px',
                                    backgroundColor: '#fff',
                                    borderLeft:"2px solid orange"
                                }}
                            >
                                <h2>Raised Surface</h2>
                                <p>This is a raised surface that covers 90% of the width.</p>
                            </Paper>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                height: '85%',
                                // backgroundColor: 'green',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white'
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    width: '95%',
                                    padding: '5px',
                                    height: "98%",
                                    backgroundColor: '#fff',
                                    borderLeft: "2px solid orange",

                                }}
                            >
                                <h5 style={{ lineHeight: "2px" }}>Total Incidents: {announcements.length}</h5>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>

                                        <Box
                                            sx={{
                                                padding: '10px',
                                                backgroundColor: '#f5f5f5', // Light grey background
                                                borderRadius: '8px', // Rounded corners
                                                height: "100%"


                                            }}
                                        >
                                            <strong>Incidents</strong>
                                            <hr />
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <strong style={{ color: "red" }}>C</strong>: 3|
                                                <strong style={{ color: "orange" }}>B</strong>: 3|
                                                <strong style={{ color: "green" }}>R</strong>: 3|
                                                <strong style={{ color: "purple" }}>N</strong>: 3|
                                                <strong style={{ color: "black" }}>E</strong>: 3|
                                            </div>
                                            <strong>Drills</strong>
                                            <hr />
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <strong style={{ color: "red" }}>C</strong>: 3|
                                                <strong style={{ color: "orange" }}>B</strong>: 3|
                                                <strong style={{ color: "green" }}>R</strong>: 3|
                                                <strong style={{ color: "purple" }}>N</strong>: 3|
                                                <strong style={{ color: "black" }}>E</strong>: 3|
                                            </div>


                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            sx={{
                                                padding: '10px',
                                                backgroundColor: '#f5f5f5', // Light grey background
                                                borderRadius: '8px', // Rounded corners
                                                height: "100%"

                                            }}
                                        >

                                            <PieCharts label="Patients" data={patientData} height={100} />

                                        </Box>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                height: '85%',
                                // backgroundColor: 'green',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white'
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    width: '95%',
                                    padding: '5px',
                                    height: "98%",
                                    backgroundColor: '#fff',
                                    borderLeft: "2px solid orange",

                                }}
                            >
                                <h5 style={{ lineHeight: "2px" }}>Response</h5>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>

                                        <Box
                                            sx={{
                                                padding: '10px',
                                                backgroundColor: '#f5f5f5', // Light grey background
                                                borderRadius: '8px', // Rounded corners
                                                lineHeight: "23px"
                                            }}
                                        >
                                            <strong>Stakeholders</strong>
                                            <hr />

                                            <strong>Police</strong> <br />
                                            <strong>DOH</strong> <br />
                                            <strong>National Defence</strong> <br />
                                            <strong>WHO</strong> <br />

                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Box
                                            sx={{
                                                padding: '5px',
                                                backgroundColor: '#f5f5f5', // Light grey background
                                                borderRadius: '8px', // Rounded corners

                                                height: "100%"
                                            }}
                                        >
                                            <ConsumptionChart data={teamDataset} series={teamSeries} height={150} />
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
                <Grid style={{ height: "0vh" }}>
                    <Grid item sm={4} sx={{ backgroundColor: "rgba(0,0,256,1)" }}>
                        {/* <ConsumptionChart data={ppeDataset} series={ppeSeries} height={200} />
                        <PieCharts label="Ambulances" data={ambulanceData} height={100} />
                        <ConsumptionChart data={teamDataset} series={teamSeries} height={200} /> */}


                    </Grid>
                    <Grid item sm={4}>
                        <div sx={{ width: "100%", color: "rgba(0,0,256,0.2)" }}>

                        </div>
                    </Grid>
                    <Grid item sm={4}>
                        {/* <Box>
                        <Stack sx={{height:"90vh",overflow:"scroll"}}>
                            {
                                announcements && announcements.map((item) => {
                                    return <Box
                                    sx={{
                                      marginBottom: "5px",
                                      padding: "10px",
                                      border: "1px solid #ccc",
                                      borderRadius: "8px",
                                      backgroundColor: "#f9f9f9",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      

                                    }}
                                  >
                                    <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                                      Title: {item.title}
                                    </div>
                                    <div style={{ marginBottom: "10px" }}>
                                      Location: {item.location}
                                    </div>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                      
                                      <TeamDialog announcementId={item.id}/>
                                      <Button variant="outlined" color="secondary" onClick={()=>{navigate(`/active-incident/${item.id}`)}}>View</Button>
                                    </div>
                                  </Box>
                                })
                            }

                        </Stack>
                    </Box> */}
                    </Grid>

                </Grid>
            </div>
            <DashboardMap announcements={announcements} height="40vh" />
        </Sidebar>
    )
}

export default Dashboard