// AIzaSyCI8O91FfQgfevadyNo9pKNt52MT5RZp_4
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

// Function to dynamically load the Google Maps script
function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    script.onload = () => {
        if (typeof window.google !== 'undefined') {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
            placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
        }
    };
    position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

export default function ExactLocation({ formData, setFormData }) {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    // Effect to load the Google Maps script
    React.useEffect(() => {
        if (typeof window !== 'undefined' && !loaded.current) {
            if (!document.querySelector('#google-maps')) {
                loadScript(
                    `https://maps.googleapis.com/maps/api/js?key=AIzaSyCI8O91FfQgfevadyNo9pKNt52MT5RZp_4&libraries=places`,
                    document.querySelector('head'),
                    'google-maps'
                );
            } else {
                autocompleteService.current = new window.google.maps.places.AutocompleteService();
                placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
            }
            loaded.current = true;
        }
    }, []);

    // Memoized and debounced fetch function for API calls
    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                if (autocompleteService.current) {
                    autocompleteService.current.getPlacePredictions(request, callback);
                }
            }, 400),
        []
    );

    // Effect to fetch new location suggestions based on input value
    React.useEffect(() => {
        let active = true;

        if (autocompleteService.current && inputValue !== '') {
            fetch({ input: inputValue }, (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        } else {
            setOptions(value ? [value] : []);
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    // Function to fetch place details including geometry
    const fetchPlaceDetails = (placeId, callback) => {
        if (placesService.current) {
            placesService.current.getDetails({ placeId }, callback);
        }
    };

    return (
        <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No locations"
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                if (newValue && newValue.place_id) {
                    fetchPlaceDetails(newValue.place_id, (place) => {
                        const location = place.formatted_address;
                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();
                        setFormData({
                            ...formData,
                            location: location,
                            lat: lat,
                            lng: lng
                        });
                    });
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params}  
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                }} label="E-Exact Location" fullWidth />
            )}
            renderOption={(props, option) => {
                const matches =
                    option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
