import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
//   yAxis: [
//     {
//       label: 'rainfall (mm)',
//     },
//   ],
  width: 300,
  height:100,
  marginBottom:0,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};


const valueFormatter = (value) => `${value}mm`;



export default function ConsumptionChart({data, series, height=250, width=350}) {

  return (
    <BarChart
      dataset={data}
      xAxis={[{ scaleType: 'band', dataKey: 'category' }]}
      series={series}
      {...chartSetting}
      height={height}
      width={width}
    />
  );
}
