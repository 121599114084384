import React, { useState, useEffect } from 'react';
import { navigate, useNavigate } from 'react-router-dom';
import { TextField, Box, Button, Grid, Switch, FormControlLabel, MenuItem, Chip, Autocomplete, Typography } from '@mui/material';

import ExactLocation from './ExactLocation';
import IncidentTypeService from '../../services/IncidentTypeService';
import AnnouncementService from '../../services/AnnouncementService';

const cbrneHazards = [
    // Chemical Hazards
    "Ammonia",
    "Chlorine",
    "Sarin",
    "Mustard gas",
    "Phosgene",
    "VX (nerve agent)",
    "Hydrogen cyanide",
    "Carbon monoxide",
    "Sulfur dioxide",
    "Nitrogen dioxide",
    "Benzene",
    "Formaldehyde",
    "Toluene",
    "Ethylene oxide",
    "Pesticides",
    "Mercury",
    "Lead",
    "Arsenic",
    "Cyanogen chloride",
    "Hydrogen sulfide",

    // Biological Hazards
    "Anthrax",
    "Smallpox",
    "Plague",
    "Botulism",
    "Tularemia",
    "Viral hemorrhagic fevers (e.g., Ebola)",
    "Ricin",
    "Brucellosis",
    "Q fever",
    "Hantavirus",
    "Dengue fever",
    "Zika virus",
    "Cholera",
    "Tuberculosis",
    "Influenza (pandemic strains)",
    "SARS",
    "MERS",
    "HIV/AIDS",
    "Malaria",
    "West Nile virus",

    // Radiological Hazards
    "Cesium-137",
    "Cobalt-60",
    "Iodine-131",
    "Strontium-90",
    "Plutonium-239",
    "Uranium-235",
    "Radium-226",
    "Americium-241",
    "Polonium-210",
    "Radon",
    "Technetium-99m",
    "Tritium",
    "Thorium-232",
    "Curium-244",
    "Neptunium-237",
    "Promethium-147",
    "Yttrium-90",
    "Iridium-192",
    "Carbon-14",
    "Phosphorus-32",

    // Nuclear Hazards
    "Nuclear reactor meltdown",
    "Improvised nuclear device (IND)",
    "Dirty bomb (radiological dispersal device)",
    "Nuclear weapon detonation",
    "Nuclear fallout",
    "Criticality accident",
    "Plutonium exposure",
    "Neutron radiation",
    "Alpha radiation exposure",
    "Beta radiation exposure",
    "Gamma radiation exposure",
    "Spent nuclear fuel",
    "Enriched uranium",
    "Depleted uranium",
    "Reactor coolant leak",
    "Control rod failure",
    "Nuclear waste transportation accident",
    "Reprocessing plant accident",
    "Weapons-grade fissile material",
    "Fusion energy accident",

    // Explosive Hazards
    "TNT (trinitrotoluene)",
    "C-4 (composition C-4)",
    "RDX (Research Department Explosive)",
    "PETN (Pentaerythritol tetranitrate)",
    "ANFO (Ammonium nitrate/fuel oil)",
    "Dynamite",
    "Nitroglycerin",
    "Black powder",
    "TATP (Triacetone triperoxide)",
    "HMX (High Melting Explosive)",
    "Semtex",
    "Plastic explosives",
    "Landmines",
    "Improvised explosive devices (IEDs)",
    "Pipe bombs",
    "Car bombs",
    "Suicide vests",
    "Grenades",
    "Rocket-propelled grenades (RPGs)",
    "Mortar shells"
];


const MethaneForm = ({ simulate = false }) => {

    const navigate = useNavigate();

    // Incident Types
    const [incidentTypes, setIncidentTypes] = useState([]);
    const loadIncidentTypes = async () => {
        try {
            let response = await IncidentTypeService.findAll(1, 10);
            if (response && response.status === 200) {
                setIncidentTypes(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadIncidentTypes();
    }, []);

    const [formData, setFormData] = useState({
        title: "test",
        description: "test",
        isMajor: false,
        location: "",
        landmark: "",
        incidentTypeId: '',
        hazards: [],
        access: [],
        lowCasualities: [],
        highCasualities: [],
        mediumCasualities: [],
        erServicePresent: [],
        erServiceRequired: [],
        drill: simulate,

    });


    const handleSubmit = async (event) => {
        event.preventDefault();
        const hazards = formData.hazards.join(",");
        const access = formData.access.join(",");
        const lowCasualities = formData.lowCasualities.join(",");
        const mediumCasualities = formData.mediumCasualities.join(",");
        const highCasualities = formData.highCasualities.join(",");
        const erServicePresent = formData.erServicePresent.join(",");
        const erServiceRequired = formData.erServiceRequired.join(",");

        const body = {
            ...formData,
            hazards, access, lowCasualities, mediumCasualities, highCasualities, erServicePresent, erServiceRequired
        }

        const response = await AnnouncementService.create(body);
        if (!response.data.error) {
            !simulate ? navigate("/activation") : navigate("/activation/drills");
        }
    };

    const handleMajor = (event) => {
        setFormData({ ...formData, isMajor: event.target.checked });
    };

    return (
        <Box>
            <Typography variant='h4'>{simulate ? "Drill Activation" : "Activation"}</Typography>
            <form onSubmit={handleSubmit} style={{ width: "80vw" }}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <FormControlLabel label="M- Major Incident" control={
                            <Switch
                                checked={formData.isMajor}
                                onChange={handleMajor}
                                color="warning"
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } />
                    </Grid>
                    <Grid item>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <ExactLocation formData={formData} setFormData={setFormData} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="E- Landmark"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.landmark}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {
                                        setFormData({ ...formData, landmark: event.target.value });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            fullWidth
                            label="T- Type Of Incident"
                            name="incidentType"
                            value={formData.incidentTypeId}
                            onChange={({ target }) => setFormData(({ ...formData, incidentTypeId: target.value }))}
                            variant="outlined"
                        >
                            {incidentTypes && incidentTypes?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.incident_type_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            options={cbrneHazards.map((option) => option)}
                            defaultValue={[cbrneHazards[13]]}
                            freeSolo
                            onChange={(event, newValue) => {
                                setFormData({ ...formData, hazards: newValue })
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} key={index}  {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="H-Potential Hazards"
                                    placeholder="Hazards"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            options={formData.access.map((option) => option)}
                            freeSolo
                            onChange={(event, newValue) => {
                                setFormData({ ...formData, access: newValue })
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="A- Access"
                                    placeholder="Access"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={[].map((option) => option)}
                                freeSolo
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, lowCasualities: newValue })
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="N- Low Casualties"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={[].map((option) => option)}
                                freeSolo
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, mediumCasualities: newValue })
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="N- Medium Casualties"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={[].map((option) => option)}
                                freeSolo
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, highCasualities: newValue })
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="N- High Casualties"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={[].map((option) => option)}
                                freeSolo
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, erServicePresent: newValue })
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="E- Emergency services Present"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                options={[].map((option) => option)}
                                freeSolo
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, erServiceRequired: newValue })
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="E- Emergency services Required"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default MethaneForm;
