import React from 'react'
import { Card, Typography, Chip, Stack } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import PieCharts from '../Charts/PieChart'

const casualities = [{ label: "C", value: 20 }, { label: "B", value: 10 }, { label: "R", value: 10 }, { label: "N", value: 11 }, { label: "E", value: 11 }]
const haphazards = ["Long-term Health Effects", "Environmental", "Psychological", "Economic", "Social and Political Instability"]
function METHANE({ announcement }) {

    const {
        id,
        title,
        drill,
        isMajor,
        description,
        location,
        landmark,
        hazards,
        access,
        lowCasualities,
        mediumCasualities,
        highCasualities,
        erServicePresent,
        erServiceRequired,
        incident_type,
        createdAt
    } = announcement;
    return (
        <div style={{ height: "82vh", overflow: "auto" }}>

            <div>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Id: {id} <br />
                        Title: {title} <br />
                        Date: {new Date(createdAt).toLocaleDateString()} <br/>
                        Description: {description} <br/>
                        Drill: {drill ? "Yes" : "No"}
                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        
                        M- Major: {isMajor ? "Yes" : "No"}


                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        E- Exact Location
                    </AccordionSummary>
                    <AccordionDetails>
                        Location- {location}
                        <br />
                        Landmark- {landmark}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        T- Type of Incident
                    </AccordionSummary>
                    <AccordionDetails>
                        {incident_type?.incident_type_name}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        H- Potential Hazards
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            hazards?.split(",").map((hazard) => hazard && <Chip label={hazard} color="primary" variant="outlined" />)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        A- Potential Access
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            access?.split(",").map((hazard, index) => hazard && <Chip key={index} label={hazard} color="primary" variant="outlined" />)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        N- Number and Type of Casualities
                    </AccordionSummary>
                    <AccordionDetails>
                        Low <br />
                        {
                            lowCasualities?.split(",").map((item, index) => item && <Chip key={index} label={item} color="primary" variant="outlined" />)
                        }
                        <br />Medium <br />
                        {
                            mediumCasualities?.split(",").map((item, index) => item && <Chip key={index} label={item} color="primary" variant="outlined" />)
                        }
                        <br />High<br />
                        {
                            highCasualities?.split(",").map((item, index) => item && <Chip key={index} label={item} color="primary" variant="outlined" />)
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        E- Emergency Services
                    </AccordionSummary>
                    <AccordionDetails>
                        Present <br />
                        {
                            erServicePresent?.split(",").map((item, index) => item && <Chip key={index} label={item} color="primary" variant="outlined" />)
                        }
                        <br />Required <br />
                        {
                            erServiceRequired?.split(",").map((item, index) => item && <Chip key={index} label={item} color="primary" variant="outlined" />)
                        }
                    </AccordionDetails>
                </Accordion>
            </div>

            {/* <PieCharts label="Casualities" data={casualities} legend={false} /> */}

        </div>
    )
}

export default METHANE