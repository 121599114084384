import axios from "axios";
const { REACT_APP_REST_URL } = process.env;

const TeamMemberService = {

  async findAll(page, pageSize) {
    return await axios.get("https://cbrne.takniatech.ae/api/member", { params: { page, pageSize } });
  },

  async create(entity) {
    return await axios.post("https://cbrne.takniatech.ae/api/member", entity);
  },

}

export default TeamMemberService;
