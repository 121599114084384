import React, { useState, useEffect } from 'react'

import Tooltip from '@mui/material/Tooltip';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


import CustomizableTable from '../shared/CustomizableTable';
import FormDialog from '../shared/FormDialog';
import TeamForm from './TeamForm';
import TeamMemberService from '../../services/TeamMemberService';

function TeamMemberList() {

    // Team Members
    const [members, setTeamMembers] = useState([]);
    
    const loadMembers = async () => {
        try {
            let response = await TeamMemberService.findAll(1, 100);
            if (response && response.status == "200") {
                setTeamMembers(response.data);
                setOpen(false)
            }
        } catch (error) { console.log(error) }

    }

    useEffect(() => { loadMembers() }, []);



    const headCells = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'fullname',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'role.role_name',
            numeric: true,
            disablePadding: false,
            label: 'Role',
        },
        {
            id: 'activity_status.status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            id: 'experience',
            numeric: true,
            disablePadding: false,
            label: 'Experience(yrs)',
        },
        {
            id: 'mobile',
            numeric: false,
            disablePadding: false,
            label: 'Contact',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        // {
        //     id: 'address',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Address',
        // },

    ];

    const [open, setOpen] = useState(false);
   
    return (
        <div style={{margin:"30px"}}>
            <Box sx={{display:"flex", rowGap:"20px", margin:"10px", alignItems:'center'}}>
                <Tooltip title="Add Member">
                    <IconButton onClick={() => setOpen(true)}>
                        <AddBoxOutlinedIcon color="primary" fontSize="large" />
                    </IconButton>
                </Tooltip>
                <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    variant="standard"
                    color="primary"
                    size="large"
                />
            </Box>
            <FormDialog title="Create Team Member" buttonTitle="CREATE MEMBER" component={<TeamForm setOpen={setOpen} loadMembers={loadMembers}/>} open={open} setOpen={setOpen} />
            <CustomizableTable title="Team Members" rows={members} headCells={headCells} />
        </div>
    )
}

export default TeamMemberList