import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MethaneForm from '../Announcement/MethaneForm';
import { lightBlue } from '@mui/material/colors';

export default function FormDialog({buttonTitle, title, component, setOpen, open=true}) {

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        
        onClose={handleClose}
        
      >
        <DialogTitle sx={{background:lightBlue[700], width:"100vw", color:"white", marginBottom:"5px"}}>{title}</DialogTitle>
        <DialogContent>
         {component}        
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
